
import React from "react";
import {Col, Row, Container} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import Term from "./tnc";

export default function Theme(props) { 
  const [term, setTerm] = useAsyncSetState(false);
  return (<div>
      <div
        className="v-admin-login"
        style={{background: 'url(https://cdn.vostro.cloud/hotspots/rhodes/rhodes-central-bg.jpg) 0% 0% / cover rgb(41, 106, 202)'}}
      >
        <div className="container-fluid fh" style={{ minHeight: "100vh" }}>
          <div className="row header row align-items-center">
            <Col>
              <a href="/" className="text-align-center header-text">
                <div className="presents">
                  {"billbergia presents"}
                </div>
                <div className="header-icon">
                  <span className="bold mr-2">
                    {"rhodes"}
                  </span>
                  <span className="normal mr-2">
                    {"central"}
                  </span>
                </div>
              </a>
            </Col>
          </div>
          <div className="row align-items-center fh-element">
            <div className="col">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-6 d-none d-lg-block">
                    <div className="headers">
                      <div className="heading-1">Free</div>
                      <div className="heading-2">Wi-Fi</div>
                      <div className="heading-3">Service</div>
                    </div>
                  </div>
                  <div className="col-10 d-block d-lg-none mx-auto">
                    <div className="headers text-center my-3 free-wifi-header">
                      Free WiFi Service
                    </div>
                  </div>
                  <div className="login-control mx-auto ml-lg-auto col-12 col-lg-5 col-xl-6">
                    <div className="paper shaddow paper-padding">
                      <div className="container-fluid no-gutters ">
                        <div className="row">
                          <div className="col">{props.children}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row fatfooter align-items-center">
            <div className="col">
              <div className="container">
                <div className="row footer-row">
                  <div className="col-auto">
                    <i className="fa fa-copyright mr-1"></i>
                    {"Rhodes Central 2021"}
                  </div>
                  <div className="col-auto">
                    {term && <Term onClose={() => setTerm(false)} />}
                    <a onClick={() => setTerm(!term)}>
                      {"Terms of use / Privacy Policy"}
                    </a>
                    <span> | </span>
                    <a href="/esafety" target="_blank" className="esafety-link">{"eSafety"}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
}
